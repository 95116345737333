import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Members} from '../../../../members'
import s from './members.scss'

export const MobileCardMembers = ({event, onClick}: {event: ExtendedEvent; onClick?: () => void}) => {
  const {experiments} = useExperiments()

  return (
    <div
      className={classNames(s.members, {[s.overrideMargin]: experiments.enabled(ExperimentNames.WidgetSpacingUpdates)})}
      data-hook="ev-list-item-members"
      onClick={onClick}
    >
      <Members event={event} listLayout />
    </div>
  )
}
