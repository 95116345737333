import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SocialBar} from '../../../../social-bar'
import s from './share.scss'

export const Share = ({event}: {event: wix.events.Event}) => {
  const {t} = useTranslation()
  const {experiments} = useExperiments()

  return (
    <div
      className={classNames(s.container, {
        [s.overrideMargin]: experiments.enabled(ExperimentNames.WidgetSpacingUpdates),
      })}
    >
      <SocialBar t={t} spacing={24} event={event} />
    </div>
  )
}
