import {ExperimentNames, isDateTbd} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './date.scss'
import {DateProps} from './interfaces'

export const Date = ({event}: DateProps) => {
  const {
    allBreakpoints: {isListDateEnabled, isListDateVisible_V2},
  } = useVisibilityStyles()
  const {shortStartDate} = useEventDateInformation(event.id)
  const {experiments} = useExperiments()

  const dateTbd = isDateTbd(event)
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)
  const listDateVisible = listDateLocationFormatSettingEnabled ? isListDateVisible_V2() : isListDateEnabled()

  if (!listDateVisible) {
    return null
  }

  return (
    <div
      className={classNames(s.date, {
        [s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled,
        [s.spacing]: !experiments.enabled(ExperimentNames.WidgetSpacingUpdates),
      })}
      data-hook={dateTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {shortStartDate}
    </div>
  )
}
